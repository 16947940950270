@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap");

:root {
  --primary-color: #9ccddc;
  --secondary-color: #ced7e0;
  --tertiary-color: #4a90e2;
  --quaternary-color: #272822;
  --quinary-color: #333;
  --senary-color: #0a0a0a;
}

body {
  background-color: #0a0a0a;
  color: #ced7e0;
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: #ced7e0;
  font-family: "Nunito Sans", sans-serif;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;

}

.content {
  text-align: center;
  max-width: 800px;
  padding: 20px;
  z-index: 2;
  position: relative;
  border-radius: 10px;
}

h1 {
  font-size: 2.5rem;
}

span {
  padding-right: 15px;
}

.name-gradient {
  background: linear-gradient(to right, #9ccddc, #ced7e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.programming-languages {
  margin-top: 20px;
}

.programming-languages code {
  background: #272822;
  color: #ced7e0;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  display: inline-block;
}

.social-links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social-links a {
  margin: 0 20px;
  transition: transform 0.3s ease;
  color: #ced7e0;
}

.social-links a:hover {
  transform: scale(1.2);
  color: #ced7e0;
}

.social-links svg {
  width: 25px;
  height: 25px;
}

.certifications {
  margin-top: 20px;
}

@keyframes zoom {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }
}

.certifications a {
  display: inline-block;
  color: #ced7e0;
  text-decoration: none;
  animation: zoom 3s ease-in-out infinite;
}

.certifications a:hover {
  color: #4a90e2;
  text-decoration: underline;
}

.outreach-message {
  padding-top: 32px;
  text-align: center;

}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.nav-link {
  margin: 0 20px;
  color: #ced7e0;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 15px;
}

.nav-link:hover,
.nav-link:focus {
  color: #ced7e0;
  transform: scale(1.1);
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

.project-card {
  background: #ced7e0;
  color: #333;
  opacity: 85%;
  width: calc((100% / 3) - 40px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-info {
  padding: 15px;
  text-align: center;
}

.project-info h3 {
  margin-top: 0;
}

.project-link {
  margin: 12px;
}

.project-info a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
}

.project-info a:hover {
  text-decoration: underline;
}

.project-info svg {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .content {
    width: 90%;
    max-width: none;
  }

  h1 {
    font-size: 2rem;
  }

  .social-links svg {
    width: 20px;
    height: 20px;
  }

  .programming-languages code,
  .social-links a {
    margin: 0 10px;
    padding: 3px 8px;
  }

  .project-card {
    width: calc(100% - 20px);
    margin-bottom: 20px;
  }

  .project-image {
    height: 180px;
  }
}

@media (max-width: 480px) {
  .content {
    padding: 10px;
    width: 95%;
  }

  h1 {
    font-size: 1.3rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 5px;
  }

  .social-links a,
  .programming-languages code {
    margin: 2px;
    padding: 2px 6px;
  }

  .social-links svg {
    width: 18px;
    height: 18px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.8rem;
    text-align: center;
  }

  .outreach-message {
    padding-top: 20px;
    text-align: center;
    
  }

  .project-card {
    width: 100%;
    padding: 8px;
  }

  .project-info h3,
  .project-info p {
    font-size: 0.9rem;
  }
}

@media (max-width: 375px) {
  .content {
    padding: 5px;
    width: 98%;
  }

  h1,
  .programming-languages span,
  .certifications span {
    font-size: 1.2rem;
  }

  .social-links a,
  .programming-languages code {
    margin: 1px;
    padding: 2px 4px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.75rem;
  }

  .project-card {
    padding: 5px;
    margin-bottom: 15px;
  }

  .project-info {
    padding: 10px;
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #0a0a0a;
  overflow: hidden;
  z-index: -100;
  opacity: 50%;
}

h1 {
  color: #ced7e0;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #5591a9;
  top: 96%;
  left: 30%;
  animation-duration: 11s;
  animation-delay: -49s;
  transform-origin: 9vw 17vh;
  box-shadow: -40vmin 0 5.699536538321561vmin currentColor;
}

.background span:nth-child(1) {
  color: #5591a9;
  top: 32%;
  left: 98%;
  animation-duration: 39s;
  animation-delay: -11s;
  transform-origin: -5vw -19vh;
  box-shadow: 40vmin 0 5.2271464194942485vmin currentColor;
}

.background span:nth-child(2) {
  color: #5591a9;
  top: 86%;
  left: 44%;
  animation-duration: 43s;
  animation-delay: -14s;
  transform-origin: 1vw -5vh;
  box-shadow: 40vmin 0 5.3568297325924625vmin currentColor;
}

.background span:nth-child(3) {
  color: #ced7e0;
  top: 98%;
  left: 98%;
  animation-duration: 47s;
  animation-delay: -1s;
  transform-origin: -18vw 20vh;
  box-shadow: 40vmin 0 5.835031889319886vmin currentColor;
}

.background span:nth-child(4) {
  color: #5591a9;
  top: 48%;
  left: 94%;
  animation-duration: 43s;
  animation-delay: -48s;
  transform-origin: -7vw 21vh;
  box-shadow: -40vmin 0 5.443271251344757vmin currentColor;
}

.background span:nth-child(5) {
  color: #ced7e0;
  top: 87%;
  left: 10%;
  animation-duration: 31s;
  animation-delay: -21s;
  transform-origin: 6vw 9vh;
  box-shadow: -40vmin 0 5.648696731455473vmin currentColor;
}

.background span:nth-child(6) {
  color: #5591a9;
  top: 97%;
  left: 37%;
  animation-duration: 45s;
  animation-delay: -13s;
  transform-origin: 13vw 0vh;
  box-shadow: -40vmin 0 5.011216488096416vmin currentColor;
}

.background span:nth-child(7) {
  color: #9ccddc;
  top: 98%;
  left: 90%;
  animation-duration: 35s;
  animation-delay: -45s;
  transform-origin: -8vw 9vh;
  box-shadow: 40vmin 0 5.104840742421324vmin currentColor;
}

.background span:nth-child(8) {
  color: #9ccddc;
  top: 66%;
  left: 5%;
  animation-duration: 9s;
  animation-delay: -30s;
  transform-origin: 5vw 19vh;
  box-shadow: -40vmin 0 5.353373305708766vmin currentColor;
}

.background span:nth-child(9) {
  color: #9ccddc;
  top: 79%;
  left: 47%;
  animation-duration: 13s;
  animation-delay: -25s;
  transform-origin: -14vw 4vh;
  box-shadow: -40vmin 0 5.1431718471531855vmin currentColor;
}

.background span:nth-child(10) {
  color: #ced7e0;
  top: 58%;
  left: 31%;
  animation-duration: 49s;
  animation-delay: -19s;
  transform-origin: 15vw 4vh;
  box-shadow: 40vmin 0 5.467597146307995vmin currentColor;
}

.background span:nth-child(11) {
  color: #ced7e0;
  top: 41%;
  left: 41%;
  animation-duration: 25s;
  animation-delay: -17s;
  transform-origin: -20vw -7vh;
  box-shadow: 40vmin 0 5.141608406305628vmin currentColor;
}

.background span:nth-child(12) {
  color: #9ccddc;
  top: 22%;
  left: 26%;
  animation-duration: 6s;
  animation-delay: -10s;
  transform-origin: -5vw -9vh;
  box-shadow: 40vmin 0 5.121809507451031vmin currentColor;
}

.background span:nth-child(13) {
  color: #5591a9;
  top: 63%;
  left: 19%;
  animation-duration: 23s;
  animation-delay: -42s;
  transform-origin: -23vw 19vh;
  box-shadow: -40vmin 0 5.950028795479834vmin currentColor;
}

.background span:nth-child(14) {
  color: #ced7e0;
  top: 80%;
  left: 88%;
  animation-duration: 40s;
  animation-delay: -4s;
  transform-origin: -4vw -11vh;
  box-shadow: -40vmin 0 5.6804321442503625vmin currentColor;
}

.background span:nth-child(15) {
  color: #5591a9;
  top: 72%;
  left: 19%;
  animation-duration: 47s;
  animation-delay: -31s;
  transform-origin: -5vw 5vh;
  box-shadow: -40vmin 0 5.145523050097612vmin currentColor;
}

.background span:nth-child(16) {
  color: #5591a9;
  top: 13%;
  left: 62%;
  animation-duration: 21s;
  animation-delay: -6s;
  transform-origin: 20vw -12vh;
  box-shadow: 40vmin 0 5.193148007441042vmin currentColor;
}

.background span:nth-child(17) {
  color: #9ccddc;
  top: 100%;
  left: 2%;
  animation-duration: 43s;
  animation-delay: -26s;
  transform-origin: -21vw -21vh;
  box-shadow: -40vmin 0 5.238159208601717vmin currentColor;
}

.background span:nth-child(18) {
  color: #5591a9;
  top: 67%;
  left: 89%;
  animation-duration: 52s;
  animation-delay: -47s;
  transform-origin: -20vw 11vh;
  box-shadow: 40vmin 0 5.251080624389878vmin currentColor;
}

.background span:nth-child(19) {
  color: #5591a9;
  top: 7%;
  left: 8%;
  animation-duration: 15s;
  animation-delay: -27s;
  transform-origin: 15vw 1vh;
  box-shadow: -40vmin 0 5.21895783548594vmin currentColor;
}

@media (max-width: 768px) {
  .App {
    justify-content: flex-start;
    padding-top: 30px;
  }

  body {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 10px;
    width: 90%;
  }

  .navbar {
    padding: 5px 0;
    font-size: 1rem;
  }

  .nav-link {
    padding: 8px 10px;
    font-size: 1rem;
  }

  .social-links svg,
  .project-info svg {
    width: 20px;
    height: 20px;
  }

  .project-card {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 10px;
  }

  .social-links a,
  .programming-languages code {
    margin: 5px;
    padding: 3px 8px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.9rem;
  }

  .outreach-message {
    padding-top: 20px;
    text-align: center;

  }

  .project-card {
    width: calc((100% / 3) - 40px);
    padding: 15px;
    margin: 0 15px;
  }

  .project-info h3,
  .project-info p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .App {
    justify-content: flex-start;
    padding-top: 20px;
  }

  body {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 10px;
    width: 95%;
  }

  .navbar {
    padding: 5px 0;
    font-size: 0.9rem;
  }

  .nav-link {
    padding: 8px 10px;
    font-size: 0.9rem;
  }

  .social-links svg,
  .project-info svg {
    width: 18px;
    height: 18px;
  }

  .project-card {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 1.3rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 5px;
  }

  .social-links a,
  .programming-languages code {
    margin: 2px;
    padding: 2px 6px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.8rem;
  }

  .outreach-message {
    padding-top: 20px;
    text-align: center;

  }

  .project-card {
    width: 100%;
    padding: 8px;
    margin: 0 15px;
  }

  .project-info h3,
  .project-info p {
    font-size: 0.9rem;
  }
}
@media (max-width: 375px) {
  .App {
    justify-content: flex-start;
    padding-top: 20px;
  }

  body {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 8px;
    width: 92%;
  }

  .navbar {
    padding: 3px 0;
    font-size: 0.85rem;
  }

  .nav-link {
    padding: 6px 8px;
    font-size: 0.85rem;
  }

  .social-links svg,
  .project-info svg {
    width: 14px;
    height: 14px;
  }

  .project-card {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 1.2rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 3px;
  }

  .social-links a,
  .programming-languages code {
    margin: 1px;
    padding: 2px 5px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.75rem;
  }

  .outreach-message {
    padding-top: 15px;
    text-align: center;

  }

  .project-card {
    width: calc(100% - 10px);
    padding: 6px;
    margin: 0 15px;
  }

  .project-info h3,
  .project-info p {
    font-size: 0.85rem;
  }
}


